import '../../../styles/pages/spring-summer-2021/touchdown-on-the-red-planet.scss';
import React from "react";
import Layout from '../../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../../components/spring-summer-2021-stories/more-stories"
import YouTube from 'react-youtube';
import Link from "gatsby-plugin-transition-link/AniLink";

import socialBanner from "../../../images/spring-summer-2021/social-banners/touchdown-on-the-red-planet.jpg";

import laurenDucharmeLab from "../../../images/spring-summer-2021/touchdown-on-the-red-planet/Lauren-Ducharme-Lab.jpg";


export default class Index extends React.Component {

  render() {
    var pageTitle = 'Touchdown on the Red Planet';
    var pageDesc = 'Mechanical engineering alumna describes ‘seven minutes of terror’ before historic Mars 2020 landing.';
    var slug = 'touchdown-on-the-red-planet';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="mars-wrapper">
          <article className="wrap small story-content" style={{marginTop: '120px'}}>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <ul className="pager">
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/">1</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link active"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/star-struck/">2</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/fun-facts/">3</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/history-buff-space-career/">4</Link>
              </li>
            </ul>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <figure>
                <img src={laurenDucharmeLab} alt="Lauren Ducharme in lab" />
              </figure>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h1>Star-Struck for Space</h1>
            <p>A 6-year-old DuCharme became awestruck with the universe while visiting her grandfather in Arizona and searching for satellites with her dad in the desert sky.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I’ll never forget the way the sky looked at night. I’d never seen so many stars in my life. I remember being so overwhelmed, not wanting to even blink because it was so beautiful.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>While studying engineering at Cal State Fullerton, she joined the student-led Titan Rover team, which designs and builds the next generation of Mars rover for collegiate competition. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>As an undergraduate, she landed an internship at the Jet Propulsion Laboratory in 2014 and worked on developing leading technologies to return Mars rock and soil samples back to Earth for analysis. Over the next several years as an intern, she had many roles on the Mars 2020 project through the development, testing and operations phases. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>DuCharme continued her education at USC, where she graduated with a master’s degree in astronautical engineering and studied spacecraft dynamics. In 2017, she was hired as a full-time flight systems engineer. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Since Mars 2020 was announced by NASA in 2012, just when I was beginning my major courses at Cal State Fullerton, I like to think that Perseverance and I grew up together,” she says. </p>
            </ScrollAnimation>

          </article>
          <ScrollAnimation animateOnce={true} animateIn="fadeInRight">
          <figure className="full-width video">
            <YouTube
            videoId="4czjS9h4Fpg"
            className="embed-item"
            containerClassName="embed-container"
            opts={{
              height: '390',
              width: '640'
            }}
            />
          </figure>
          </ScrollAnimation>
          <article className="wrap small story-content">

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2>Searching for Ancient Martian Life</h2>
            <p>Now that Percy has landed, DuCharme has transitioned her work from the cruise operations team to the surface operations team. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“On Mars, we are testing our instruments, driving the rover around and using our sample caching system to survey the Martian surface,” she says.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Over the next couple of months, we will complete preliminary checks of our systems and instruments, unstow our remote sensing mast and robotic arm, fly our robotic helicopter, ‘Ingenuity,’ to scout paths for the rover, and ease into our day-to-day science activities. We are monitoring the mission around the clock.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>Since Mars has slightly longer days than Earth, DuCharme and the surface operations team are living on “Mars time” with each day shifting about 45 minutes. She works atypical hours, sometimes starting before dawn or through the night.</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“This means over time, we will start to be nocturnal, and then slowly make our way back to a somewhat normal schedule when Earth and Mars days align,” she explains. “Once all the teams are confident their subsystems are working as expected, and we trust ourselves and the vehicle, we will eventually live on Earth time again. It’s intense, but quite exciting.” </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure className="float_right video-embed">
              <div className="embed-container"><iframe title="Video: Meet Mars explorer Lauren DuCharme" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FCSUFofficial%2Fvideos%2F166267332081137%2F&show_text=false&width=560" width="560" height="314" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true" className="embed-item"></iframe></div>
              <figcaption>Video: Meet Mars explorer Lauren DuCharme</figcaption>
            </figure>
            <p>For DuCharme, what’s also thrilling is the possibility of finding signs of Martian life. The landing site in Jezero Crater — over 3.5 billion years old — is an ancient delta once flooded with water that scientists believe could have sustained life long ago. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I can’t wait to see the discoveries Perseverance makes and the answers she gathers,” DuCharme ponders. “With the vastness of space, I firmly believe, we are not alone.” <span className="end-mark" /></p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p className="learn-more">
              Support the College of Engineering and Computer Science<br />
              <a href="https://campaign.fullerton.edu/ecs">campaign.fullerton.edu/ecs</a>
            </p>
            </ScrollAnimation>

            <br /><br />

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" className="center">
              <Link
              className="button mars-button"
              cover
              bg="
              url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
              center / cover   /* position / size */
              no-repeat        /* repeat */
              scroll           /* attachment */
              padding-box      /* origin */
              content-box      /* clip */
              black            /* color */
              "
              direction="right"
              duration={2}
              to="/spring-summer-2021/touchdown-on-the-red-planet/">
                Previous
              </Link>

              <Link
              className="button mars-button"
              cover
              bg="
              url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
              center / cover   /* position / size */
              no-repeat        /* repeat */
              scroll           /* attachment */
              padding-box      /* origin */
              content-box      /* clip */
              black            /* color */
              "
              direction="left"
              duration={2}
              to="/spring-summer-2021/touchdown-on-the-red-planet/fun-facts/">
                Next
              </Link>
            </ScrollAnimation>

            <br /><br />

          </article>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 